import React, { useContext, useEffect, useState } from 'react';
import { CoreContext } from '../../../Services/Core/Context';
import { MergeObjectValues } from '../../../Utilities/Algorithms';
import { TextField, InputAdornment } from '@mui/material';
import { MdTitle } from 'react-icons/md';
import { BsCursorText } from 'react-icons/bs';
import { GrTextWrap } from 'react-icons/gr';
import { CheckFormErrors } from '../../../Utilities/Runtime';
import { useAlert } from 'react-alert';
import { CgWebsite } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import HomePageEditableFields from './EditableFields/HomePage';
import AboutUsPageEditableFields from './EditableFields/AboutUs';
import ContactSectionEditableFields from './EditableFields/Contact';
import UseWindowSize from '../../../Hooks/WindowSize';
import Infrastructure from '../../../Infrastructure.json';

const InputAdornmentify = (Icon) => ({
    startAdornment: (
        <InputAdornment position='start'>{Icon}</InputAdornment>
    )});

const RetrieveInputProperties = (Identifier) => ({
    maxLength: Infrastructure.Validations.EditableContent[Identifier].MaxLength,
    minLength: Infrastructure.Validations.EditableContent[Identifier].MinLength
});

const Editables = ({ SetIsLoading, FormReference }) => {
    const Alert = useAlert();
    const Navigate = useNavigate();
    const [Width] = UseWindowSize();
    const { SetPlatformDynamicInfo, GetDynamicInfo, SetDynamicInfo, StoreDynamicInfo } = useContext(CoreContext);
    const [GetIsComponentMounted, SetIsComponentMounted] = useState(true);
    const [GetHeaderLeftTitle, SetHeaderLeftTitle] = useState(GetDynamicInfo['Header-Left-Title']);
    const [GetEditablesErrors, SetEditablesErrors] = useState({});
    const [GetRefresh, SetRefresh] = useState(true);
    const TitleInputAdornment = InputAdornmentify(<MdTitle />);
    const SubtitleInputAdornment = InputAdornmentify(<BsCursorText />);
    const ContentInputAdornment = InputAdornmentify(<GrTextWrap />);
    const [GetEditablesServiceBuffer, SetEditablesServiceBuffer] = useState({
        'Contact-Location': GetDynamicInfo['Contact-Location'] || '',
        'Contact-Google-Maps-URL': GetDynamicInfo['Contact-Google-Maps-URL'] || '',
        'Contact-Email': GetDynamicInfo['Contact-Email'] || '',
        'Contact-Phone-Number': GetDynamicInfo['Contact-Phone-Number'] || '',
        'Header-Left-Title': GetDynamicInfo['Header-Left-Title'] || '',
        'Home-Page-Main-Title': GetDynamicInfo['Home-Page-Main-Title'] || '',
        'Home-Page-Services-Title': GetDynamicInfo['Home-Page-Services-Title'] || '',
        'Home-Page-Services-Subtitle': GetDynamicInfo['Home-Page-Services-Subtitle'] || '',
        'Home-Page-Contact-Title': GetDynamicInfo['Home-Page-Contact-Title'] || '',
        'Home-Page-Contact-Subtitle': GetDynamicInfo['Home-Page-Contact-Subtitle'] || '',
        'About-Us-Vision-Title': GetDynamicInfo['About-Us-Vision-Title'] || '',
        'About-Us-Vision-Content': GetDynamicInfo['About-Us-Vision-Content'] || '',
        'About-Us-Objective-Title': GetDynamicInfo['About-Us-Objective-Title'] || '',
        'About-Us-Objective-Content': GetDynamicInfo['About-Us-Objective-Content'] || '',
        'About-Us-History-Title': GetDynamicInfo['About-Us-History-Title'] || '',
        'About-Us-History-Content': GetDynamicInfo['About-Us-History-Content'] || '',
        'About-Us-Mision-Content': GetDynamicInfo['About-Us-Mision-Content'] || '',
        'About-Us-Mision-Subtitle': GetDynamicInfo['About-Us-Mision-Subtitle'] || '',
        'About-Us-Mision-Title': GetDynamicInfo['About-Us-Mision-Title'] || ''
    });
    const DefaultEditorViewProperties = {
        GetEditablesServiceBuffer,
        SetEditablesServiceBuffer,
        GetEditablesErrors,
        SetEditablesErrors,
        SubtitleInputAdornment,
        TitleInputAdornment,
        ContentInputAdornment,
        RetrieveInputProperties
    };
    
    useEffect(() => {
        const FReference = FormReference.current;
        FReference.addEventListener('submit', HandleFormSubmit);
        return () => {
            FReference.removeEventListener('submit', HandleFormSubmit);
            SetRefresh(false);
            SetIsComponentMounted(false);
            SetHeaderLeftTitle('');
            SetEditablesErrors({});
            SetEditablesServiceBuffer({});
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps 

    useEffect(() => {
        SetEditablesErrors(MergeObjectValues(GetEditablesErrors, 
            CheckFormErrors({
                Validator: 'EditableContent',
                States: [
                    {
                        Identifier: 'Header-Left-Title',
                        Getter: GetHeaderLeftTitle,
                        OnLengthError: 'El título que ingresó es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres de longitud.'
                    }
                ]
            })
        ));
        const Buffer = GetEditablesServiceBuffer;
        Buffer['Header-Left-Title'] = GetHeaderLeftTitle;
        SetEditablesServiceBuffer(Buffer);
        SetRefresh(!GetRefresh);
    }, [GetHeaderLeftTitle]);  // eslint-disable-line react-hooks/exhaustive-deps 


    const HandleFormSubmit = (Event) => {
        Event.preventDefault();
        SetIsLoading(true);
        SetPlatformDynamicInfo(GetEditablesServiceBuffer).then(() => {
            StoreDynamicInfo(GetEditablesServiceBuffer);
            SetDynamicInfo(GetEditablesServiceBuffer);
            Alert.success();
            Navigate('/');
        }).finally(() => (GetIsComponentMounted) && (SetIsLoading(false)));
    };

    return (
        <>
            <article id='General-Content-Box'>
                <Fade bottom duration={(Width > 768) ? (400) : (200)}>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <CgWebsite />
                                </InputAdornment>
                            )
                        }}
                        inputProps={RetrieveInputProperties('Header-Left-Title')}
                        type='text'
                        name='Header-Left-Title'
                        error={GetEditablesErrors['Header-Left-Title']}
                        onChange={(Event) => SetHeaderLeftTitle(Event.target.value)}
                        value={GetHeaderLeftTitle}
                        required={true}
                        fullWidth={true}
                        placeholder='Título presente en la cabecera'
                        helperText={GetEditablesErrors['Header-Left-Title'] ? GetEditablesErrors['Header-Left-Title'] : 'Titulo localizado en la seccion superior izquierda de la plataforma, dentro de la cabecera, se claro y preciso con el contenido del mismo, es el titulo principal sin importar en que pagina de la plataforma se este.'} />
                </Fade>
            </article>

            <HomePageEditableFields {...DefaultEditorViewProperties} />
            <AboutUsPageEditableFields {...DefaultEditorViewProperties} />
            <ContactSectionEditableFields {...DefaultEditorViewProperties} />
        </>
    )
};

export default Editables;