import React from 'react';
import { Helmet } from 'react-helmet-async';

export const SEO = ({ Title, Description = 'Somos expertos en asesorías contables, tributarias y laborales, conoce nuestra historia, servicios y visión, contáctanos y permítenos hacer de tu negocio más eficiente y rentable.', Keywords }) => {
    Keywords = `ar contabilidad, agustin, mail, documentación, sandrino aguilar, ar, contador, servicios, contadores, sandrino, aguilar, talca, chile, maule, pyme, empresas, remuneraciones, tributarios, tributaria, contable, grande, mediana, pequeña, contacto, @sandrinoaguilar.com, misión, visión, equipo, objetivos, historia, trayectoria, ${Keywords}`;
    Title = `${Title} - Sandrino Aguilar - Asesorias Contables y Tributarias`
    return (
        <Helmet>
            <title>{Title}</title>
            <meta name='pageName' content={Title} />
            <meta name='channel' content='Sandrino Aguilar'/>
            <meta name='contentType' content='website'/>
            <meta property='og:url' content='%PUBLIC_URL%'/>
            <meta property='og:site_name' content='%PUBLIC_URL%' />
            <meta content='#FFFFFF' data-react-helmet='true' name='theme-color' />
            <meta name='keywords' content={Keywords} />
            <meta name='subTopic' content='website'/>
            <meta name='robots' content='index, follows'/>
            <meta name='canonical' href='%PUBLIC_URL%'/>
            <meta name='twitter:site' content={Title} />
            <meta name='twitter:creator' content='Sandrino Aguilar - Asesorias Contables y Tributarias'/>
            <meta name='theme-color' content='#FFFFFF'/>
            <meta name='author' content='Sandrino Aguilar - Asesorias Contables y Tributarias'/>
            <meta name='twitter:site' content='Sandrino Aguilar'/>
            <meta name='twitter:creator' content='Sandrino Aguilar - Asesorias Contables y Tributarias'/>
            <meta name='description' content=''/>
            <meta name='twitter:description' content={Description} />
            <meta property='og:description' content={Description} />
        </Helmet>
    );
};

export default SEO;