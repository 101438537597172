import React, { useEffect, useState } from 'react';
import Accordion from '../../../General/Accordion';
import { MergeObjectValues } from '../../../../Utilities/Algorithms';
import { CheckFormErrors } from '../../../../Utilities/Runtime';
import { TextField } from '@mui/material';

const AboutUsPageEditableFields = ({
    RetrieveInputProperties,
    TitleInputAdornment,
    SubtitleInputAdornment,
    ContentInputAdornment,
    GetEditablesServiceBuffer,
    SetEditablesServiceBuffer,
    GetEditablesErrors,
    SetEditablesErrors
}) => {
    const [GetAboutUsVisionTitle, SetAboutUsVisionTitle] = useState(GetEditablesServiceBuffer['About-Us-Vision-Title']);
    const [GetAboutUsVisionContent, SetAboutUsVisionContent] = useState(GetEditablesServiceBuffer['About-Us-Vision-Content']);
    const [GetAboutUsObjectiveTitle, SetAboutUsObjectiveTitle] = useState(GetEditablesServiceBuffer['About-Us-Objective-Title']);
    const [GetAboutUsObjectiveContent, SetAboutUsObjectiveContent] = useState(GetEditablesServiceBuffer['About-Us-Objective-Content']);
    const [GetAboutUsHistoryTitle, SetAboutUsHistoryTitle] = useState(GetEditablesServiceBuffer['About-Us-History-Title']);
    const [GetAboutUsHistoryContent, SetAboutUsHistoryContent] = useState(GetEditablesServiceBuffer['About-Us-History-Content']);
    const [GetAboutUsMisionContent, SetAboutUsMisionContent] = useState(GetEditablesServiceBuffer['About-Us-Mision-Content']);
    const [GetAboutUsMisionSubtitle, SetAboutUsMisionSubtitle] = useState(GetEditablesServiceBuffer['About-Us-Mision-Subtitle']);
    const [GetAboutUsMisionTitle, SetAboutUsMisionTitle] = useState(GetEditablesServiceBuffer['About-Us-Mision-Title']);
    const [GetRefresh, SetRefresh] = useState(true);

    useEffect(() => {
        return () => {
            SetRefresh(false);
            SetAboutUsVisionTitle('');
            SetAboutUsVisionContent('');
            SetAboutUsObjectiveTitle('');
            SetAboutUsObjectiveContent('');
            SetAboutUsHistoryTitle('');
            SetAboutUsHistoryContent('');
            SetAboutUsMisionContent('');
            SetAboutUsMisionSubtitle('');
            SetAboutUsMisionTitle('');
        };
    }, []);

    useEffect(() => {
        SetEditablesErrors((CurrentErrors) => MergeObjectValues(CurrentErrors, 
            CheckFormErrors({
                Validator: 'EditableContent',
                States: [
                    {
                        Identifier: 'About-Us-Vision-Title',
                        Getter: GetAboutUsVisionTitle,
                        OnLengthError: 'El título que ingresó es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres de longitud.'
                    },
                    {
                        Identifier: 'About-Us-Vision-Content',
                        Getter: GetAboutUsVisionContent,
                        OnLengthError: 'El contenido ingresado es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres.'
                    },
                    {
                        Identifier: 'About-Us-Objective-Title',
                        Getter: GetAboutUsObjectiveTitle,
                        OnLengthError: 'El título que ingresó es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres de longitud.'
                    },
                    {
                        Identifier: 'About-Us-Objective-Content',
                        Getter: GetAboutUsObjectiveContent,
                        OnLengthError: 'El contenido ingresado es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres.'
                    },
                    {
                        Identifier: 'About-Us-History-Title',
                        Getter: GetAboutUsHistoryTitle,
                        OnLengthError: 'El título que ingresó es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres de longitud.'
                    },
                    {
                        Identifier: 'About-Us-History-Content',
                        Getter: GetAboutUsHistoryContent,
                        OnLengthError: 'El contenido ingresado es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres.'
                    },
                    {
                        Identifier: 'About-Us-Mision-Content',
                        Getter: GetAboutUsMisionContent,
                        OnLengthError: 'El contenido ingresado es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres.'
                    },
                    {
                        Identifier: 'About-Us-Mision-Subtitle',
                        Getter: GetAboutUsMisionSubtitle,
                        OnLengthError: 'El subtítulo que ingresó es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres de longitud.'
                    },
                    {
                        Identifier: 'About-Us-Mision-Title',
                        Getter: GetAboutUsMisionTitle,
                        OnLengthError: 'El título que ingresó es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres de longitud.'
                    }
                ]
            }
        )));
        const Buffer = GetEditablesServiceBuffer;
        Buffer['About-Us-Vision-Title'] = GetAboutUsVisionTitle;
        Buffer['About-Us-Vision-Content'] = GetAboutUsVisionContent;
        Buffer['About-Us-Objective-Title'] = GetAboutUsObjectiveTitle;
        Buffer['About-Us-Objective-Content'] = GetAboutUsObjectiveContent;
        Buffer['About-Us-History-Title'] = GetAboutUsHistoryTitle;
        Buffer['About-Us-History-Content'] = GetAboutUsHistoryContent;
        Buffer['About-Us-Mision-Content'] = GetAboutUsMisionContent;
        Buffer['About-Us-Mision-Subtitle'] = GetAboutUsMisionSubtitle;
        Buffer['About-Us-Mision-Title'] = GetAboutUsMisionTitle;
        SetEditablesServiceBuffer(Buffer);
        SetRefresh(!GetRefresh);
    }, [GetAboutUsVisionTitle, GetAboutUsVisionContent, GetAboutUsObjectiveTitle,  // eslint-disable-line react-hooks/exhaustive-deps
        GetAboutUsObjectiveContent, GetAboutUsHistoryTitle, GetAboutUsHistoryContent, 
        GetAboutUsMisionContent, GetAboutUsMisionSubtitle, GetAboutUsMisionTitle]);

    return (
        <Accordion
            Title='Acerca de Nosotros'
            Content={(
                <div className='Options-Box'>
                    <Accordion
                        Title='Misión'
                        Content={(
                            <article>
                                <TextField
                                    InputProps={TitleInputAdornment}
                                    inputProps={RetrieveInputProperties('About-Us-Mision-Title')}
                                    type='text'
                                    name='About-Us-Mision-Title'
                                    error={GetEditablesErrors['About-Us-Mision-Title']}
                                    onChange={(Event) => SetAboutUsMisionTitle(Event.target.value)}
                                    value={GetAboutUsMisionTitle}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Título presente en la sección de misión'
                                    helperText={GetEditablesErrors['About-Us-Mision-Title'] ? GetEditablesErrors['About-Us-Mision-Title'] : 'Cuando ingrese a la página que trata de contextualizar a sus eventuales visitantes sobre su empresa, en la primera sección de la misma encontrará la sección que respeta la misión de su empresa, donde hay 3 párrafos, siendo este, el primero, sea breve con este titulo'} />

                                <TextField
                                    InputProps={SubtitleInputAdornment}
                                    inputProps={RetrieveInputProperties('About-Us-Mision-Subtitle')}
                                    type='text'
                                    name='About-Us-Mision-Subtitle'
                                    error={GetEditablesErrors['About-Us-Mision-Subtitle']}
                                    onChange={(Event) => SetAboutUsMisionSubtitle(Event.target.value)}
                                    value={GetAboutUsMisionSubtitle}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Introduzca la misión que tiene la empresa'
                                    helperText={GetEditablesErrors['About-Us-Mision-Subtitle'] ? GetEditablesErrors['About-Us-Mision-Subtitle'] : 'Subtítulo de la sección de misión de la página "Acerca de nosotros", aquí puedes agregar más información para así contextualizar mejor a tus visitantes sobre tu misión, este título será el que más se destaque en esa, sin embargo, sé preciso.'} />

                                <TextField
                                    InputProps={ContentInputAdornment}
                                    inputProps={RetrieveInputProperties('About-Us-Mision-Content')}
                                    multiline
                                    rows={5}
                                    type='text'
                                    name='About-Us-Mision-Content'
                                    error={GetEditablesErrors['About-Us-Mision-Content']}
                                    onChange={(Event) => SetAboutUsMisionContent(Event.target.value)}
                                    value={GetAboutUsMisionContent}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Complemento detallado sobre la misión de la empresa'
                                    helperText={GetEditablesErrors['About-Us-Mision-Content'] ? GetEditablesErrors['About-Us-Mision-Content'] : 'Contenido de la sección de misiones, aquí se puede complementar el contenido de la sección correspondiente teniendo en cuenta y/o considerando los respectivos títulos y subtítulos.'} />
                            </article>
                        )}
                    />

                    <Accordion
                        Title='Visión'
                        Content={(
                            <article>
                                <TextField
                                    InputProps={TitleInputAdornment}
                                    inputProps={RetrieveInputProperties('About-Us-Vision-Title')}
                                    type='text'
                                    name='About-Us-Vision-Title'
                                    error={GetEditablesErrors['About-Us-Vision-Title']}
                                    onChange={(Event) => SetAboutUsVisionTitle(Event.target.value)}
                                    value={GetAboutUsVisionTitle}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Título presente en la sección de vista'
                                    helperText={GetEditablesErrors['About-Us-Vision-Title'] ? GetEditablesErrors['About-Us-Vision-Title'] : 'Título que lleva la sección que se encarga de dar a conocer la visión que tiene la empresa, ser clara y precisa, de manera que se pueda captar la atención de sus visitantes sin necesidad de darle muchas vueltas al asunto.'} />

                                <TextField
                                    InputProps={ContentInputAdornment}
                                    inputProps={RetrieveInputProperties('About-Us-Vision-Content')}
                                    multiline
                                    rows={5}
                                    type='text'
                                    name='About-Us-Vision-Content'
                                    error={GetEditablesErrors['About-Us-Vision-Content']}
                                    onChange={(Event) => SetAboutUsVisionContent(Event.target.value)}
                                    value={GetAboutUsVisionContent}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Presenta la visión que tiene la empresa'
                                    helperText={GetEditablesErrors['About-Us-Vision-Content'] ? GetEditablesErrors['About-Us-Vision-Content'] : 'Contenido de la sección que se encarga de relatar la visión de la empresa, aquí puede ser descriptivo, para que sus visitantes puedan conocer a profundidad pero sin tantos rodeos sobre la respectiva sección.'} />
                            </article>
                        )}
                    />

                    <Accordion
                        Title='Equipo y/o Objetivos'
                        Content={(
                            <article>
                                <TextField
                                    InputProps={TitleInputAdornment}
                                    inputProps={RetrieveInputProperties('About-Us-Objective-Title')}
                                    type='text'
                                    name='About-Us-Objective-Title'
                                    error={GetEditablesErrors['About-Us-Objective-Title']}
                                    onChange={(Event) => SetAboutUsObjectiveTitle(Event.target.value)}
                                    value={GetAboutUsObjectiveTitle}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Título presente en la sección de objetivos y/o conocimiento del equipo'
                                    helperText={GetEditablesErrors['About-Us-Objective-Title'] ? GetEditablesErrors['About-Us-Objective-Title'] : 'Título de la sección donde tienes la posibilidad de presentar a tu equipo, tus objetivos y cómo quieres cumplir con todo lo propuesto.'} />

                                <TextField
                                    InputProps={ContentInputAdornment}
                                    inputProps={RetrieveInputProperties('About-Us-Objective-Content')}
                                    multiline
                                    rows={5}
                                    type='text'
                                    name='About-Us-Objective-Content'
                                    error={GetEditablesErrors['About-Us-Objective-Content']}
                                    onChange={(Event) => SetAboutUsObjectiveContent(Event.target.value)}
                                    value={GetAboutUsObjectiveContent}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Contextualize sobre el equipo y los objetivos de la empresa.'
                                    helperText={GetEditablesErrors['About-Us-Objective-Content'] ? GetEditablesErrors['About-Us-Objective-Content'] : 'Sea descriptivo, de manera que pueda dar a conocer en profundidad el contenido de la sección respectiva a sus eventuales visitantes, con la ayuda del título de la misma sección, complemente lo propuesto inicialmente en la sección elaborada.'} />
                            </article>
                        )}
                    />

                    <Accordion
                        Title='Historía de la empresa'
                        Content={(
                            <article>
                                <TextField
                                    InputProps={TitleInputAdornment}
                                    inputProps={RetrieveInputProperties('About-Us-History-Title')}
                                    type='text'
                                    name='About-Us-History-Title'
                                    error={GetEditablesErrors['About-Us-History-Title']}
                                    onChange={(Event) => SetAboutUsHistoryTitle(Event.target.value)}
                                    value={GetAboutUsHistoryTitle}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Título presente en la sección de historia de la empresa'
                                    helperText={GetEditablesErrors['About-Us-History-Title'] ? GetEditablesErrors['About-Us-History-Title'] : 'Título de la sección cuyo objeto es dar a conocer la historia de su empresa.'} />

                                <TextField
                                    InputProps={ContentInputAdornment}
                                    inputProps={RetrieveInputProperties('About-Us-History-Content')}
                                    multiline
                                    rows={5}
                                    type='text'
                                    name='About-Us-History-Content'
                                    error={GetEditablesErrors['About-Us-History-Content']}
                                    onChange={(Event) => SetAboutUsHistoryContent(Event.target.value)}
                                    value={GetAboutUsHistoryContent}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Ingrese la historia y/o trayectoria de la empresa'
                                    helperText={GetEditablesErrors['About-Us-History-Content'] ? GetEditablesErrors['About-Us-History-Content'] : 'Describa detalladamente la historia de su empresa, la trayectoria de su dueño y cómo llegó a fundarla a lo largo del tiempo y los diversos clientes con los que ha tenido la oportunidad correspondiente.'} />
                            </article>
                        )}
                    />
                </div>
            )}
        />
    );
};

export default AboutUsPageEditableFields;