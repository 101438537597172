import React, { useState, useEffect, useContext } from 'react';
import { CoreContext } from '../../../Services/Core/Context';
import { Element } from 'react-scroll';
import { IoLocationOutline, IoMailOpenOutline } from 'react-icons/io5';
import { CheckFormErrors, FormatString } from '../../../Utilities/Runtime';
import { Tooltip, Skeleton, TextField, CircularProgress } from '@mui/material';
import { useAlert } from 'react-alert';
import { BsTelephone } from 'react-icons/bs';
import UseWindowSize from '../../../Hooks/WindowSize';
import Infrastructure from '../../../Infrastructure.json';
import Fade from 'react-reveal/Fade';
import GetDynamic from '../../General/GetDynamic';
import ContactConcept from '../../../Assets/Images/Home/Contact-Concept.png';
import Button from '../../General/Button';
import './Contact.css';

const ContactComponent = () => {
    const Alert = useAlert();
    const [GetErrors, SetErrors] = useState({});
    const [GetIsComponentMounted, SetIsComponentMounted] = useState(true);
    const [GetIsLoading, SetIsLoading] = useState(false);
    const [GetIsFormInvalid, SetIsFormInvalid] = useState(false);
    const [GetEmailAddress, SetEmailAddress] = useState('');
    const [GetFullname, SetFullname] = useState('');
    const [GetMessage, SetMessage] = useState('');
    const [Width] = UseWindowSize();
    const Validator = Infrastructure.Validations.Contact;
    const { GetError, 
        Contact, 
        GetDynamicInfo, 
        GetIsDynamicInfoLoading } = useContext(CoreContext);

    useEffect(() => {
        return () => {
            SetErrors({});
            SetIsComponentMounted(false);
            SetIsFormInvalid(false);
            SetIsLoading(false);
            SetFullname('');
            SetMessage('');
            SetEmailAddress('');
        };
    }, []);

    useEffect(() => {
        SetErrors(CheckFormErrors({
            Validator: 'Contact',
            States: [
                {
                    Identifier: 'Fullname',
                    Getter: GetFullname,
                    OnLengthError: 'Debe proporcionarnos su nombre completo para que podamos comunicarnos con usted, asegúrese de que tenga entre {{MinLength}} y {{MaxLength}} caracteres.'
                },
                {
                    Identifier: 'Email',
                    Getter: GetEmailAddress,
                    Validations: ['IsEmail'],
                    OnIsEmailError: 'El correo electrónico que ha proporcionado no es correcto, por favor verifique que sea correcto y acate la dirección correcta que debe tener.'
                },
                {
                    Identifier: 'Message',
                    Getter: GetMessage,
                    OnLengthError: 'La solicitud que nos informará para que luego podamos comunicarnos con usted de acuerdo con la información proporcionada, debe contener entre {{MinLength}} y {{MaxLength}} caracteres.'
                }
            ]
        }));
    }, [GetFullname, GetEmailAddress, GetMessage]);

    const HandleFormSubmit = (Event) => {
        Event.preventDefault();
        SetIsLoading(true);
        Contact({
            Fullname: GetFullname,
            Email: GetEmailAddress,
            Message: GetMessage
        })
        .then(Alert.success)
        .finally(() => (GetIsComponentMounted) && (SetIsLoading(false)));
    };

    useEffect(() => {
        SetIsFormInvalid(
            !GetFullname.length ||
            !GetEmailAddress.length ||
            !GetMessage.length ||
            GetErrors.Fullname !== undefined ||
            GetErrors.Emai !== undefined ||
            GetErrors.Message !== undefined
        );
    }, [GetErrors]); // eslint-disable-line react-hooks/exhaustive-deps

    const ContactOptions = [
        [IoLocationOutline, 'Contact-Location', 'Google-Maps-URL'],
        [IoMailOpenOutline, 'Contact-Email', 'mailto:{{DynamicValue}}'],
        [BsTelephone, 'Contact-Phone-Number', 'tel:{{DynamicValue}}']
    ];

    return (
        <Element name='Contact' id='Contact-Box'>
            <article id='Left-Box'>
                <Fade top duration={(Width > 768) ? (200) : (100)}>
                    <div id='Header-Form-Box'>
                        <div className='Cursor-Hover-Effect'>
                            <GetDynamic
                                Identifier='Home-Page-Contact-Title'
                                Render={({ Value }) => <h3>{Value}</h3>} />
                        </div>
                        <GetDynamic
                            Identifier='Home-Page-Contact-Subtitle'
                            Render={({ Value }) => <p>{Value}</p>} />
                    </div>
                </Fade>
                <form id='Form-Box' onSubmit={HandleFormSubmit}>
                    <div>
                        <Fade top duration={(Width > 768) ? (300) : (150)}>
                            <h4>Nombre completo</h4>
                        </Fade>
                        <Fade bottom duration={(Width > 768) ? (400) : (250)}>
                            <Tooltip placement='bottom-end' title='Para volver a comunicarnos con usted necesitamos saber su nombre, por favor indíquenoslo.' >
                                <TextField
                                    inputProps={{
                                        maxLength: Validator.Fullname.MaxLength,
                                        minLength: Validator.Fullname.MinLength
                                    }}
                                    value={GetFullname}
                                    onChange={(Event) => SetFullname(Event.target.value)}
                                    error={!!GetErrors.Fullname}
                                    required={true}
                                    fullWidth={true}
                                    helperText={(GetErrors.Fullname) && (GetErrors.Fullname)}
                                    type='text' />
                            </Tooltip>
                        </Fade>
                    </div>

                    <div>
                        <Fade top duration={(Width > 768) ? (300) : (150)}>
                            <h4>Dirección de correo electrónico</h4>
                        </Fade>
                        <Fade bottom duration={(Width > 768) ? (400) : (250)}>
                            <Tooltip placement='top-end' title='¿Hacia dónde debemos redirigir nuestra respuesta? Indíquenos su dirección de correo electrónico.'>
                                <TextField 
                                    onChange={(Event) => SetEmailAddress(Event.target.value)}
                                    value={GetEmailAddress}
                                    error={!!GetErrors.Email}
                                    required={true}
                                    fullWidth={true}
                                    helperText={(GetErrors.Email) && (GetErrors.Email)}
                                    type='email' />
                            </Tooltip>
                        </Fade>
                    </div>

                    <div>
                        <Fade top duration={(Width > 768) ? (300) : (150)}>
                            <h4>Cuentanos, ¿Cómo te podemos ayudar?</h4>
                        </Fade>
                        <Fade bottom duration={(Width > 768) ? (400) : (250)}>
                            <Tooltip placement='bottom-end' title='Describa detalladamente su propuesta y/o inquietud, para que podamos ser claros ante una eventual respuesta.'>
                                <TextField
                                    inputProps={{
                                        maxLength: Validator.Message.MaxLength,
                                        minLength: Validator.Message.MinLength
                                    }}
                                    onChange={(Event) => SetMessage(Event.target.value)}
                                    value={GetMessage}
                                    error={!!GetErrors.Message}
                                    required={true}
                                    fullWidth={true}
                                    helperText={(GetErrors.Message) && (GetErrors.Message)}
                                    type='text' />
                            </Tooltip>
                        </Fade>
                    </div>

                    {(GetError) && (
                        <article className='Form-Error-Container'>
                            <p>{GetError}</p>
                        </article>
                    )}

                    <Fade bottom duration={(Width > 768) ? (500) : (300)}>
                        {(!GetIsLoading) ? (
                            <Button 
                                IsDisabled={GetIsFormInvalid}
                                FullWidth={(Width < 768)} 
                                Content='Enviar mensaje' 
                                Variant='Contained' />
                        ) : (
                            <div id='Circular-Progress-Box'>
                                <CircularProgress size={'2rem'} />
                            </div>
                        )}
                    </Fade>
                </form>
            </article>

            <figure id='Right-Box'>
                <Fade right duration={600}>
                    <img src={ContactConcept} className='Desktop-Box' alt='Ilustración de contacto' />
                </Fade>
                <Fade 
                    {...((Width > 768) ? ({ bottom: true }) : ({ top: true }))} 
                    duration={(Width > 768) ? (700) : (300)}
                >
                    <figcaption id='Contact-Options-Box'>
                        {(!GetIsDynamicInfoLoading) ? (
                            ContactOptions.map(([ Icon, Key, HREFValue ], Index) => (
                                <div 
                                    key={Index} 
                                    onClick={() => window.location.href = GetDynamicInfo[HREFValue] 
                                        || FormatString(HREFValue, { DynamicValue: GetDynamicInfo[Key] })}>
                                    <i>
                                        <Icon />
                                    </i>
                                    <GetDynamic
                                        Identifier={Key}
                                        Render={({ Value }) => <span>{Value}</span>} />
                                </div>
                            ))
                        ) : (
                            Array.from(Array(3).keys()).map((Index) => (
                                <Skeleton key={Index} height={30} width='80%' />
                            ))
                        )}
                    </figcaption>
                </Fade>
            </figure>
        </Element>
    );
};

export default ContactComponent;
