import React, { useEffect, useState } from 'react';
import Accordion from '../../../General/Accordion';
import { CheckFormErrors } from '../../../../Utilities/Runtime';
import { TextField } from '@mui/material';
import { MergeObjectValues } from '../../../../Utilities/Algorithms';

const HomePageEditableFields = ({
    RetrieveInputProperties,
    TitleInputAdornment,
    SubtitleInputAdornment,
    SetEditablesServiceBuffer,
    GetEditablesServiceBuffer,
    GetEditablesErrors,
    SetEditablesErrors
}) => {
    const [GetHomePageMainTitle, SetHomePageMainTitle] = useState(GetEditablesServiceBuffer['Home-Page-Main-Title']);
    const [GetHomePageServicesTitle, SetHomePageServicesTitle] = useState(GetEditablesServiceBuffer['Home-Page-Services-Title']);
    const [GetHomePageServicesSubtitle, SetHomePageServicesSubtitle] = useState(GetEditablesServiceBuffer['Home-Page-Services-Subtitle']);
    const [GetHomePageContactTitle, SetHomePageContactTitle] = useState(GetEditablesServiceBuffer['Home-Page-Contact-Title']);
    const [GetHomePageContactSubtitle, SetHomePageContactSubtitle] = useState(GetEditablesServiceBuffer['Home-Page-Contact-Subtitle']);
    const [GetRefresh, SetRefresh] = useState(true);

    useEffect(() => {
        return () => {
            SetRefresh(false);
            SetHomePageMainTitle('');
            SetHomePageServicesTitle('');
            SetHomePageServicesSubtitle('');
            SetHomePageContactTitle('');
            SetHomePageContactSubtitle('');
        };
    }, []);

    useEffect(() => {
        SetEditablesErrors((CurrentErrors) => MergeObjectValues(CurrentErrors, 
            CheckFormErrors({
                Validator: 'EditableContent',
                States: [
                    {
                        Identifier: 'Home-Page-Main-Title',
                        Getter: GetHomePageMainTitle,
                        OnLengthError: 'El título que ingresó es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres de longitud.'
                    },
                    {
                        Identifier: 'Home-Page-Services-Title',
                        Getter: GetHomePageServicesTitle,
                        OnLengthError: 'El título que ingresó es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres de longitud.'
                    },
                    {
                        Identifier: 'Home-Page-Services-Subtitle',
                        Getter: GetHomePageServicesSubtitle,
                        OnLengthError: 'El subtítulo que ingresó es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres de longitud.'
                    },
                    {
                        Identifier: 'Home-Page-Contact-Title',
                        Getter: GetHomePageContactTitle,
                        OnLengthError: 'El título que ingresó es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres de longitud.'
                    },
                    {
                        Identifier: 'Home-Page-Contact-Subtitle',
                        Getter: GetHomePageContactSubtitle,
                        OnLengthError: 'El subtítulo que ingresó es incorrecto, verifique que este tenga entre {{MinLength}} y {{MaxLength}} caracteres de longitud.'
                    }
                ]
            }
        )));
        const Buffer = GetEditablesServiceBuffer;
        Buffer['Home-Page-Main-Title'] = GetHomePageMainTitle;
        Buffer['Home-Page-Services-Title'] = GetHomePageServicesTitle;
        Buffer['Home-Page-Services-Subtitle'] = GetHomePageServicesSubtitle;
        Buffer['Home-Page-Contact-Title'] = GetHomePageContactTitle;
        Buffer['Home-Page-Contact-Subtitle'] = GetHomePageContactSubtitle;
        SetEditablesServiceBuffer(Buffer);
        SetRefresh(!GetRefresh);
    }, [GetHomePageMainTitle, GetHomePageServicesTitle, GetHomePageServicesSubtitle, GetHomePageContactTitle, GetHomePageContactSubtitle]);  // eslint-disable-line react-hooks/exhaustive-deps 

    return (
        <Accordion
            Title='Página de Inicio'
            Content={(
                <div className='Options-Box'>
                    <Accordion
                        Title='Inicio'
                        Content={(
                            <article>
                                <TextField
                                    InputProps={TitleInputAdornment}
                                    inputProps={RetrieveInputProperties('Home-Page-Main-Title')}
                                    type='text'
                                    name='Home-Page-Main-Title'
                                    error={GetEditablesErrors['Home-Page-Main-Title']}
                                    onChange={(Event) => SetHomePageMainTitle(Event.target.value)}
                                    value={GetHomePageMainTitle}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Título principal presente en la página de inicio'
                                    helperText={GetEditablesErrors['Home-Page-Main-Title'] ? GetEditablesErrors['Home-Page-Main-Title'] : 'Dentro de la página principal, en la sección izquierda se encontrará con el presente título, en donde, está pensando en describir el contenido de manera general el contenido de su plataforma así como también de que trata su respectiva empresa.'} />
                            </article>
                        )}
                    />
                    
                    <Accordion
                        Title='Servicios'
                        Content={(
                            <article>
                                <TextField
                                    InputProps={TitleInputAdornment}
                                    inputProps={RetrieveInputProperties('Home-Page-Services-Title')}
                                    type='text'
                                    name='Home-Page-Services-Title'
                                    error={GetEditablesErrors['Home-Page-Services-Title']}
                                    onChange={(Event) => SetHomePageServicesTitle(Event.target.value)}
                                    value={GetHomePageServicesTitle}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Título presente en la sección de servicios'
                                    helperText={GetEditablesErrors['Home-Page-Services-Title'] ? GetEditablesErrors['Home-Page-Services-Title'] : 'Al momento de ingresar a la página principal y acceder a la sección de los servicios que presta su respectiva empresa, el título de presentación en relación con la respectiva sección será el que tiene presente en este instante para modificar.'} />

                                <TextField
                                    InputProps={SubtitleInputAdornment}
                                    inputProps={RetrieveInputProperties('Home-Page-Services-Subtitle')}
                                    type='text'
                                    name='Home-Page-Services-Subtitle'
                                    error={GetEditablesErrors['Home-Page-Services-Subtitle']}
                                    onChange={(Event) => SetHomePageServicesSubtitle(Event.target.value)}
                                    value={GetHomePageServicesSubtitle}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Subtítulo presente en la sección de servicios'
                                    helperText={GetEditablesErrors['Home-Page-Services-Subtitle'] ? GetEditablesErrors['Home-Page-Services-Subtitle'] : 'Subtítulo del título principal de la sección de servicios ubicado en la página principal, ubicado para idealizar a sus eventuales visitantes sobre el contenido que presentará la sección correspondiente, esto, también con ayuda del título anterior.'} />
                            </article>
                        )}
                    />

                    <Accordion
                        Title='Contacto'
                        Content={(
                            <article>
                                <TextField
                                    InputProps={TitleInputAdornment}
                                    inputProps={RetrieveInputProperties('Home-Page-Contact-Title')}
                                    type='text'
                                    name='Home-Page-Contact-Title'
                                    error={GetEditablesErrors['Home-Page-Contact-Title']}
                                    onChange={(Event) => SetHomePageContactTitle(Event.target.value)}
                                    value={GetHomePageContactTitle}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Título presente en la sección de contacto'
                                    helperText={GetEditablesErrors['Home-Page-Contact-Title'] ? GetEditablesErrors['Home-Page-Contact-Title'] : 'Titulo que es presentado dentro de la seccion de contacto la cual se encuentra ubicada en la pagina principal de la plataforma, sea claro y preciso con el contenido de este titulo, de manera que se logre captar la atencion de sus visitantes sin arruinar su atractivo visual que posee en aquella seccion.'} />

                                <TextField
                                    InputProps={SubtitleInputAdornment}
                                    inputProps={RetrieveInputProperties('Home-Page-Contact-Subtitle')}
                                    type='text'
                                    name='Home-Page-Contact-Subtitle'
                                    error={GetEditablesErrors['Home-Page-Contact-Subtitle']}
                                    onChange={(Event) => SetHomePageContactSubtitle(Event.target.value)}
                                    value={GetHomePageContactSubtitle}
                                    required={true}
                                    fullWidth={true}
                                    placeholder='Subtítulo presente en la sección de contacto'
                                    helperText={GetEditablesErrors['Home-Page-Contact-Subtitle'] ? GetEditablesErrors['Home-Page-Contact-Subtitle'] : 'Subtitulo el cual desea complementar el titulo presentado con anterioridad en relación a la sección de contacto, aqui usted puede ser un poco más descriptivo en donde puede complementar la contextualización de la presente sección, procure no excederse demasiado.'} />
                            </article>
                        )}
                    />
                </div>
            )}
        />
    );
};

export default HomePageEditableFields;