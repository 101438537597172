import React, { useEffect, useState } from 'react';
import Accordion from '../../../General/Accordion';
import { CheckFormErrors } from '../../../../Utilities/Runtime';
import { TextField, InputAdornment } from '@mui/material';
import { IoLocationOutline, IoMailOpenOutline } from 'react-icons/io5';
import { BsTelephone, BsMap } from 'react-icons/bs';
import { MergeObjectValues } from '../../../../Utilities/Algorithms';

const ContactSectionEditableFields = ({ 
    RetrieveInputProperties,
    SetEditablesServiceBuffer,
    GetEditablesServiceBuffer,
    GetEditablesErrors,
    SetEditablesErrors
}) => {
    const [GetContactLocation, SetContactLocation] = useState(GetEditablesServiceBuffer['Contact-Location']);
    const [GetContactGoogleMapsURL, SetContactGoogleMapsURL] = useState(GetEditablesServiceBuffer['Contact-Google-Maps-URL']);
    const [GetContactEmail, SetContactEmail] = useState(GetEditablesServiceBuffer['Contact-Email']);
    const [GetContactPhoneNumber, SetContactPhoneNumber] = useState(GetEditablesServiceBuffer['Contact-Phone-Number']);
    const [GetRefresh, SetRefresh] = useState(true);

    useEffect(() => {
        return () => {
            SetRefresh(false);
            SetContactLocation('');
            SetContactGoogleMapsURL('');
            SetContactEmail('');
            SetContactPhoneNumber('');
        };
    }, []);

    useEffect(() => {
        SetEditablesErrors((CurrentErrors) => MergeObjectValues(CurrentErrors, 
            CheckFormErrors({
                Validator: 'EditableContent',
                States: [
                    {
                        Identifier: 'Contact-Location',
                        Getter: GetContactLocation,
                        OnLengthError: 'La dirección donde se encuentra establecida su empresa o sus respectivas oficinas debe tener entre {{MinLength}} y {{MaxLength}} caracteres.'
                    },
                    {
                        Identifier: 'Contact-Google-Maps-URL',
                        Getter: GetContactGoogleMapsURL,
                        Validations: ['IsURL'],
                        OnIsURLError: 'La URL de Google Maps que redirige a la ubicación de su empresa u oficinas no es válida.',
                        OnLengthError: 'La URL de Google Maps que redirige a la ubicación de su empresa u oficina debe tener {{MinLength}} y {{MaxLength}} caracteres.'
                    },
                    {
                        Identifier: 'Contact-Email',
                        Getter: GetContactEmail,
                        Validations: ['IsEmail'],
                        OnIsEmailError: 'El formato del correo electrónico de contacto es incorrecto, ingrese un correo electrónico válido.'
                    },
                    {
                        Identifier: 'Contact-Phone-Number',
                        Getter: GetContactPhoneNumber,
                        OnLengthError: 'El número de teléfono ingresado es incorrecto, este debe tener entre {{MinLength}} y {{MaxLength}} caracteres.'
                    }
                ]
            }
        )));
        const Buffer = GetEditablesServiceBuffer;
        Buffer['Contact-Location'] = GetContactLocation;
        Buffer['Contact-Google-Maps-URL'] = GetContactGoogleMapsURL;
        Buffer['Contact-Email'] = GetContactEmail;
        Buffer['Contact-Phone-Number'] = GetContactPhoneNumber;
        SetEditablesServiceBuffer(Buffer);
        SetRefresh(!GetRefresh);
    }, [GetContactLocation, GetContactGoogleMapsURL, GetContactEmail, GetContactPhoneNumber]);  // eslint-disable-line react-hooks/exhaustive-deps 

    return (
        <Accordion
            Title='Datos de contacto'
            Content={(
                <div className='Options-Box'>
                    <article>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <IoLocationOutline />
                                    </InputAdornment>
                                )
                            }}
                            inputProps={RetrieveInputProperties('Contact-Location')}
                            type='text'
                            name='Contact-Location'
                            error={GetEditablesErrors['Contact-Location']}
                            onChange={(Event) => SetContactLocation(Event.target.value)}
                            value={GetContactLocation}
                            required={true}
                            fullWidth={true}
                            placeholder='Ingrese la dirección de la empresa y/o sus oficinas'
                            helperText={GetEditablesErrors['Contact-Location'] ? GetEditablesErrors['Contact-Location'] : '.Introduce la dirección de la empresa o de sus oficinas, para que coincida con la información que proporcionas adicionalmente en los anuncios de terceros.'} />
                    </article>

                    <article>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <IoMailOpenOutline />
                                    </InputAdornment>
                                )
                            }}
                            type='text'
                            error={GetEditablesErrors['Contact-Email']}
                            name='Contact-Email'
                            onChange={(Event) => SetContactEmail(Event.target.value)}
                            value={GetContactEmail}
                            required={true}
                            fullWidth={true}
                            placeholder='Ingrese el correo electrónico de contacto'
                            helperText={GetEditablesErrors['Contact-Email'] ? GetEditablesErrors['Contact-Email'] : 'Ingresa el correo de contacto, donde todos los E-Mails que se envían desde la sección de contacto que se encuentra en la página principal de la plataforma se envían al correo que aquí indicas, además, esta dirección será visible en la sección correspondiente para que tus potenciales clientes también puedan contactarte.'} />
                    </article>

                    <article>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <BsTelephone />
                                    </InputAdornment>
                                )
                            }}
                            error={GetEditablesErrors['Contact-Phone-Number']}
                            inputProps={RetrieveInputProperties('Contact-Phone-Number')}
                            type='text'
                            name='Contact-Phone-Number'
                            onChange={(Event) => SetContactPhoneNumber(Event.target.value)}
                            value={GetContactPhoneNumber}
                            required={true}
                            fullWidth={true}
                            placeholder='Ingrese el número de contacto'
                            helperText={GetEditablesErrors['Contact-Phone-Number'] ? GetEditablesErrors['Contact-Phone-Number'] : 'Establece el número de contacto que tiene tu empresa, para que tus eventuales clientes puedan contactarte a través de este número que estará visible en la página de inicio.'} />
                    </article>

                    <article>
                        <TextField
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <BsMap />
                                    </InputAdornment>
                                )
                            }}
                            error={GetEditablesErrors['Contact-Google-Maps-URL']}
                            inputProps={RetrieveInputProperties('Contact-Google-Maps-URL')}
                            type='text'
                            name='Contact-Google-Maps-URL'
                            onChange={(Event) => SetContactGoogleMapsURL(Event.target.value)}
                            value={GetContactGoogleMapsURL}
                            required={true}
                            fullWidth={true}
                            placeholder='Ingrese la dirección comercial en Google Maps (URL)'
                            helperText={GetEditablesErrors['Contact-Google-Maps-URL'] ? GetEditablesErrors['Contact-Google-Maps-URL'] : 'Enlace y/o URL de la dirección exacta de la ubicación de las oficinas de su empresa dentro de Google Maps, de manera que al hacer clic en la dirección de la misma, aparecerá una pestaña con Google Maps indicando la dirección correspondiente.'} />
                    </article>
                </div>
            )}
        />
    );
};

export default ContactSectionEditableFields;